import { MatchGroup, MatchStatus } from "types/entities/Match";

import React from "react";
import { ApiOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useStatus } from "hooks/useStatus";
import { useTranslations } from "translations/useTranslations";

import { FeatureFlagNames } from "modules/AuthModule/featureFlags/featureFlags.types";
import { useFeatureFlag } from "modules/AuthModule/featureFlags/useFeatureFlag";
import { getSelectedGroups } from "modules/MatchingModule/helpers/getSelectedGroups";
import { useMatchingContext } from "modules/MatchingModule/MatchingModule.context";
import { useMatchItem } from "modules/MatchingModule/MatchingTargetsModule/hooks/useMatchItem";
import { useDeleteSynonym } from "modules/SynonymModule/SynonymUpdateModule/api/useDeleteSynonym";

import { MatchingMatchButtonProps } from "./MatchingMatchButton.types";

export const MatchingMatchButton = React.memo<MatchingMatchButtonProps>((props) => {
  const { selectedGroupIds, matchGroupsList, commonStatus } = useMatchingContext();
  const { setButtonStatus, clearButtonStatus, isButtonDisabled } = useStatus();

  const { itemID, taxonomyItem, iconOnly, className } = props;
  const translations = useTranslations();
  const { match, isMatchGroupsUpdating } = useMatchItem();
  const enabled = useFeatureFlag(FeatureFlagNames.MATCHING_ACTION_MATCH);
  const catalogSynonymDeleteEnabled = useFeatureFlag(FeatureFlagNames.CATALOG_SYNONYM_DELETE);

  const groups = getSelectedGroups(matchGroupsList, selectedGroupIds);
  const getFirstItem = (arr: MatchGroup[] = []): MatchGroup | null => arr[0] || null;
  const queryText: string = getFirstItem(groups)?.match_text ?? '';
  const { deleteSynonym, isLoading } = useDeleteSynonym({ taxonomyItemId: itemID, synonymItemId: "", matchTxt: queryText });

  const handleMatch = React.useCallback(() => {
    match(itemID, taxonomyItem);
    clearButtonStatus();
  }, [itemID, taxonomyItem, match]);

  const handleRemoveSyn = React.useCallback(() => {
    deleteSynonym();
    setButtonStatus(itemID, queryText, true);
  }, [deleteSynonym]);

  if (!enabled) return null;

  return (
    <>
      <Button
        type="primary"
        className={className}
        icon={<ApiOutlined />}
        onClick={handleMatch}
        disabled={isLoading || isButtonDisabled(itemID, queryText)}
        loading={isMatchGroupsUpdating}
      >
        {iconOnly ? null : translations["matching.target.buttons.match"]}
      </Button>
      {
        commonStatus === MatchStatus.CONFLICT && catalogSynonymDeleteEnabled
        &&
        <Button
          type="dashed"
          disabled={isButtonDisabled(itemID, queryText)}
          danger
          className={className}
          onClick={handleRemoveSyn}
          loading={isLoading}
        >
          {translations["matching.target.buttons.remove"]}
        </Button>
      }
    </>
  );
});
