import { useCallback } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useStatus = () => {

    const { data: disabledButtons = {}, refetch } = useQuery<{
        [key: string]: { match_text: string; isDisabled: boolean }[];
    }>(
        ["disabledButtons"],
        async () => {
            const savedState = JSON.parse(localStorage.getItem("disabledButtons") || "{}");
            return savedState;
        }
    );

    const updateDisabledButtons = useMutation(
        async (updatedState: {
            [key: string]: { match_text: string; isDisabled: boolean }[];
        }) => {
            localStorage.setItem("disabledButtons", JSON.stringify(updatedState));
            return updatedState;
        },
        {
            onSuccess: () => {
                refetch();
            },
        }
    );

    const setButtonStatus = useCallback(
        (id: string, match_text: string, isDisabled: boolean) => {
            const currentStates = disabledButtons[id] || [];
            const updatedStates = [
                ...currentStates.filter((state) => state.match_text !== match_text),
                { match_text, isDisabled },
            ];
            updateDisabledButtons.mutate({
                ...disabledButtons,
                [id]: updatedStates,
            });
        },
        [disabledButtons, updateDisabledButtons]
    );

    const clearButtonStatus = () => {
        updateDisabledButtons.mutate({});
    };

    const isButtonDisabled = useCallback(
        (id: string, qry_text?: string): boolean => {
            const button = disabledButtons[id];
            if (!button) return false;

            if (qry_text !== undefined) {
                const matchingState = button.find((state) => state.match_text === qry_text);
                return matchingState ? matchingState.isDisabled : false;
            }

            return button.some((state) => state.isDisabled);
        },
        [disabledButtons]
    );

    return { setButtonStatus, clearButtonStatus, isButtonDisabled };
}